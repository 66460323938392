import "@polygon-software/bomify/dist/bomify.css";

import Vue from "vue";
import bomify from "@polygon-software/bomify";
import merge from "lodash/merge.js";
import VueHead from "vue-head";

import routes from "./router/routes.js";
import empty from "./store/empty.js";
import de from "./languages/de.js";
import fr from "./languages/fr.js";

import App from "./App.vue";

// bom-framework parameters
const bom = {
  api_url: window.bomconfig.api_url,
  api_token: window.bomconfig.api_token,
  client_name: window.bomconfig.client_name,
  client_id: window.bomconfig.client_id,
  language: window.bomconfig.language,
};

Vue.use(bomify, bom);

function deepen(obj) {
  let result = {};
  Object.keys(obj).forEach((obj_key) => {
    // Split path into component parts
    const parts = obj_key.split(".");
    // Create sub-objects along path as needed
    const reversed = parts.reverse();
    const first = reversed.shift();
    result[first] = obj[obj_key];
    reversed.forEach((part) => {
      result = {[part]: result};
    });
  });

  return result;
}
Vue.mixin({
  props: {
    customText: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  created() {
    if (this.$i18n !== undefined && this.$i18n.messages !== undefined) {
      this.$i18n.availableLocales.forEach((loc) => {
        if (loc in this.customText) {
          const obj = {...this.$i18n.messages[loc]};
          this.$i18n.setLocaleMessage(loc, merge(obj, deepen(this.customText[loc])));
        }
      });
    }
  },
});

// Vuetify Theme
const theme = {
  themes: {
    dark: {
      primary: (window.bomconfig.color || "#fa9615"),
      secondary: "#fa9615",
      accent: "#ff6666",
    },
  },
};

// i18n languages
const languages = {
  de,
  fr,
};

// Vuex Store Modules
const store_modules = {
  empty,
};
const router_options = {
  props: {default: true},
};

const bomify_plugins = bomify.plugins({
  bom, theme, languages, routes, store_modules, router_options,
});

bomify.bomify.$auth.activateRouteGuard("login", "clientChooser");
bomify.bomify.preventRerenderingForRoutes("login", "clientChooser");

Vue.use(VueHead);

new Vue({
  ...bomify_plugins,
  render: (h) => h(App),
}).$mount("#app");
