<template>
  <div>
    <b-page-title
      :title="$t(tTitle)"
    />
    <p v-html=$t(tAdditionalText) />
    <v-btn
      :href="$t(tFileUrl)" download
    >
      {{ $t(tTextDownloadButton) }}
      <v-icon small right>
        mdi-download
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
/**
 * The Download File page shows just the title and a button with text. By clicking
 * on the button a file will be downloaded.
 *
 * @displayName Download File
 */

export default {
  name: "BDownloadFile02",
  props: {
    /**
     * The title of the page
     */
    tTitle: {type: String, required: false, default: "b.pages.download.title"},
    /**
     * The text on the download button
     */
    tTextDownloadButton: {type: String, required: false, default: "b.pages.download.download_button"},
    /**
     * The url of the file which will be downloaded
     */
    tFileUrl: {type: String, required: true},
    /**
     * Additional Text above the download button
     */
    tAdditionalText: {type: String, required: false, default: "b.pages.download.additional_text"},
  },
};
</script>

<style scoped>

</style>
