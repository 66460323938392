<template>
  <div>
    <b-card-group>
      <b-image-card
        v-for="(menu_item, index) in menu_items"
        :key="menu_item.title + index"
        :image="resolveImage(menu_item.image)"
        :title="$t(menu_item.title)"
        :style="cssVars"

      >
        <p
          class="mb-1"
          v-for="(link, index) in menu_item.links"
          :key="link.path + index"
        >
          <a
            v-if="link.path.startsWith('mailto:')"
            class="subtitle-1 font-weight-bold text-decoration-none"
            :href="link.path"
          >
            {{$t(link.text)}}
          </a>

          <router-link
            v-else-if="link.path.startsWith('/')"
            class="subtitle-1 font-weight-bold text-decoration-none"
            :to="{path: link.path, params: (link.params || {}), query: (link.props || {})}"
            v-show="!link.hide"
          >
            {{$t(link.text)}}
          </router-link>

          <a
            v-else
            class="subtitle-1 font-weight-bold text-decoration-none"
            :href="link.path"
            target="_blank"
          >
            {{$t(link.text)}}
          </a>
        </p>
      </b-image-card>

<!--      <b-menu-card
        v-for="(menu_item, index) in menu_items"
        :key="menu_item.title + index"
        :title="menu_item.title"
        :image="menu_item.image"
        :links="menu_item.links"
      />-->
    </b-card-group>
<!--    <b-full-image-card
      v-if="ads.length > 0"
      :link="ad_url"
      :image="ad_img"
    />-->
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import default_image from "../assets/imagePlaceholders/std-private.png";

export default {

  name: "Home",
  data() {
    return {
      menu_items: window.bomconfig.home,
    };
  },
  computed: {
    ...mapGetters("user", {
      user_image: "getUserImage",
      client: "getClient",
    }),
    cssVars() {
      return {
        "--link-color": window.bomconfig?.menu_card?.link_color,
      };
    },
  },
  methods: {
    resolveImage(image_string) {
      if (image_string === "D:PROFILE_IMAGE") {
        return this.user_image || default_image;
      }
      return image_string;
    },
  },
};
</script>

<style scoped>
.v-application a {
  color: var(--link-color, var(--primary))
}
</style>
