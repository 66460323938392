export default {
  hello: "Ein neues $bomify Projekt ist am Start :D",
  home: "Home",
  login_failed: "Login Fehlgeschlagen",
  menu: {
    profile: {
      title: "Mein Profil",
      address: "Adresse",
      communication: "Kommunikation",
      settings: "Kontoeinstellungen",
    },
    who_is_who: {
      title: "Who's Who",
      search: "Suchen",
      remembered: "Gemerkte Kontakte",
      contacts: "Meine Kontakte",
    },
    events: {
      title: "Was geht ab",
      events: "Events",
    },
    services: {
      title: "Services",
      documents: "SGLWT Unterlagen",
      statutes: "SGLWT Statuten",
      contact: "Kontakt Sekretariat",
    },
    marketplace: {
      title: "Marktplatz",
      jobs: "Stellenangebote",
      internships: "Praktika",
      shop: "Inserate / Shop",
    },
  },
  marketplace: {
    jobs: "Stellenangebote",
    interships: "Praktika",
    shop: "Inserate/Shop",
  },
  documents: {
    report: "Jahresberichte",
    protocol: "GV Protokolle",
    varia: "Varia",
  },
  sidemenu: {
    home: "Home",
    statutes: "Statuten",
    settings: "Einstellungen",
    impressum: "Impressum",
  },
  events: {
    title: "Events",
  },
  statutes: {
    file_url: "/images/events.jpg",
  },
  tabs: {
    profile: "Mein Profil",
    communication: "Kommunikation",
    impressum: "Impressum",
    address_details: "Adresse Details",
    company_address: "Firma",
    event_search: "Eventsuche",
    noted_addresses: "Gemerkt",
    contact_addresses: "Kontakt",
    event_details: "Event Details",
    person_search: "Who's Who",
    marketplace: "Marktplatz",
    marketplace_details: "Marktplatz Eintrag",
    documents: "Dokumente",
    documents_details: "Dokument Eintrag",
    settings: "Einstellungen",
    statutes: "Statuten",
    login: "Login",
  },
  info_channels: {
    newsletter: "Newsletter",
    job_offers: "Jobangebote",
  },
  b: {
    pages: {
      personal_address: {
        title: "Adresse ansehen",
      },
    },
  },
};
