<template>
  <b-app :dark="dark" :style="{ backgroundColor: site_background }">

    <template v-slot:navigation>
      <b-navigation-wrapper
        v-if="visible"
        :logo="logo"
        v-model="sidebar_open"
        :menu-items="menuItems"
        :profile-action="sidemenuProfileClick"
        :has-language-dropdown="hasLanguageDropdown"
      />
    </template>

    <template v-slot:default>
      <v-container fluid>
        <v-row
          no-gutters
        >
          <v-col
            cols="12"
            lg="10"
            offset-lg="1"
            xl="8"
            offset-xl="2"
          >
            <router-view v-if="!config_error"/>
            <b-error-alert v-model="config_error" :message="error_message"/>
            <b-development-banner
              :visible="dev_mode"
            >
              <b-language-debug-toggle
                class="debug ml-6"
              />
            </b-development-banner>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template v-slot:footer>
      <div class="footer">
        <p v-html="$t('developed_by')"/>
      </div>
    </template>

  </b-app>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "App",
  data: () => ({
    sidebar_open: false,
    visible: true,
    user: {first_name: "Max", last_name: "Mustermann", mail: "mail@mail.mail"},
    config_error: false,
    error_message: "",
    dark: !!window.bomconfig.darkmode,
    menuItems: window.bomconfig.sidemenu,
    hasLanguageDropdown: window.bomconfig.language_dropdown,
    sidemenuProfileClick: window.bomconfig.sidemenu_profile_click,
    logo: window.bomconfig.logo,
  }),
  created() {
    if (!this.$bomify.$bom.logged_in) {
      this.$bomify.$router.push({
        path: `/login?redirect=${encodeURI(this.$route.path)}`,
      });
    }
    this.$bomify.$message.debug(true);
    if (!window.bomconfig) {
      this.setError(this.$t("b.error.no_bom_config"));
      return;
    }
    if (window.bomconfig?.trust_messages_from){
      this.$bomify.$message.addTrustedSources(window.bomconfig?.trust_messages_from);
    }
    this.$bomify.$message.addTrustedSources([`${window.location.protocol}//${window.location.host}`]);
    this.assertRequiredConfigs();
    if (window.bomconfig.color) {
      this.$bomify.$vuetify.theme.themes.light.primary = window.bomconfig.color;
      this.$bomify.$vuetify.theme.themes.dark.primary = window.bomconfig.color;
    }
    if (window.bomconfig.add_modules) {
      window.bomconfig.add_modules.forEach((module_definition) => {
        if (!this.$bom[module_definition.name]) {
          this.$bom.addModule(module_definition);
        }
      });
    }
    this.$bomify.$bom.onLogoutSuccessfull(() => {
      this.$bomify.$router.push({
        path: window?.bomconfig.logout_redirect,
      });
    });
  },
  computed: {
    dev_mode() {
      return !window.bomconfig.production;
    },
    ...mapGetters("config", {
      getThemeVariable: "getThemeVariable",
    }),
    site_background() {
      return this.getThemeVariable("site_background");
    },
  },
  methods: {
    assertRequiredConfigs() {
      const required_config_keys = [
        "api_url",
        "api_token",
        "client_id",
        "module",
      ];
      required_config_keys.forEach((required_key) => {
        this.assertConfig(required_key);
      });
    },
    assertConfig(entry) {
      if (!window.bomconfig[entry]) {
        this.setError(this.$t("b.error.missing_config_entry", { entry }));
      }
    },
    setError(message) {
      this.config_error = true;
      this.error_message = `ERROR: ${message}`;
    },
  },
  watch: {
    $route() {
      this.$emit("updateHead");
    },
  },
  head: {
    title() {
      const { tabName } = this.$route.meta;
      return {
        inner: tabName ? this.$t(tabName) : "",
        separator: tabName ? "|" : "",
        complement: window.bomconfig?.meta?.title || "Bomify Portal",
      };
    },
    meta() {
      return Object.entries(window.bomconfig?.meta || {}).map(([name, content]) => {
        return { name, content, id: name };
      });
    },
  },
};
</script>

<style>
div.footer {
  padding-top: 30px;
  width: 100%;
}
div.footer > p {
  width: 100%;
  text-align: center;
  margin-bottom: 0;
  padding-bottom: 0;
  font-size: 0.8rem;
  color: grey;
}
div.footer >>> p * {
  color: grey;
  text-decoration: none;
}
.debug >>> .v-label {
  color: black;
  font-size: 16px;
}
.debug >>> .v-input--selection-controls__input * {
  font-size: 20px;
}
</style>
