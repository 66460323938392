import * as bomify from "@polygon-software/bomify";
import Login from "../pages/Login.vue";
import ClientChooser from "../pages/ClientChooser.vue";
import BDownloadFile02 from "../pages/BDownloadFile02.vue";
import Home from "../pages/Home.vue";
import BInstanceSearch02 from "@/pages/BInstanceSearch02";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      logged_in_only: true,
      tabName: "Home",
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/choose-client",
    name: "clientChooser",
    component: ClientChooser,
  },
  {
    path: "/insight",
    name: "insight",
    component: BDownloadFile02,
    props: {
      tFileUrl: "/files/swissVR_promise_Beschreibung.pdf",
      tTitle: "insight.title",
      tAdditionalText: "insight.additionalText",
      tTextDownloadButton: "insight.button",
    },
  },
  {
    path: "/event-search02",
    name: "event-search",
    component: BInstanceSearch02,
    props: {
      tTitle: "Anlässe",
      hasArchive: true,
    },
  },
];
window.bomconfig.pages.forEach((page) => {
  routes.push({
    path: page.path,
    name: page.name,
    component: bomify.default[page.page],
    meta: {
      tabName: page.tabName,
      ...page.meta,
    },
    props: (route) => ({
      ...page.props,
      ...route.params,
    }),
  });
});
export default routes;
