<template>
  <div>
    <b-page-title
      :title="$t(tTitle)"
    />
    <b-search-input
      v-if="hasSearchInput"
      v-model="user_search"
      @enter="search"
    />
    <div v-if="hasSearchDatePicker">
      <v-row>
        <b-search-date-picker
          v-model="start_date"
          :label="$t(tLabelStart)"
        />
        <b-search-date-picker
          v-model="end_date"
          :label="$t(tLabelEnd)"
        />
      </v-row>
    </div>
    <b-all-clients-switch
      v-if="hasAllClientsSwitch"
      v-model="all_clients"
      :label-false="tLabelFalse"
      :label-true="tLabelTrue"
    />
    <b-search-button
      v-if="hasSearchButton"
      @click="search"
    />

    <!-- NEXT EVENTS -->
    <b-reference-section
      v-if="hasNextItem"
      :title="$t(tTitleNextItem)"
      :items="next_item"
      :loading-items="loading_item"
      :path-on-click="pathOnClick"
    />

    <!-- REGISTERED EVENTS -->
    <b-registered-reference-section
      v-if="hasRegisteredItems"
      :image="image"
      :show-switch="hasAllClientsSwitch"
      :path-on-click="pathOnReferenceClick"
      :label-true="tLabelTrue"
      :label-false="tLabelFalse"
    />

    <!-- FURTHER EVENTS -->
    <b-reference-section
      v-if="hasFurtherItems"
      :title="$t(tTitleFurtherItem)"
      :items="items"
      :loading-items="loading_items"
      :load-more-items="onIntersect"
      :path-on-click="pathOnClick"
    />

    <!-- Archive -->
    <b-reference-section
      v-if="hasArchive"
      :title="$t(tTitleArchiveItem)"
      :items="archiveItems"
      :loading-items="loading_archiveItems"
      :load-more-items="onIntersectArchive"
      :path-on-click="pathOnClick"
    />
  </div>
</template>

<script>
import add from "../../node_modules/date-fns/add/index.js";
import set from "../../node_modules/date-fns/set/index.js";
import default_image from "../assets/imagePlaceholders/std-event.png";

/**
 * On this page you can search for instances (for example events). You have the option of having a
 * search input field where you can search for instances based on the words entered.
 * You also have the option of specifying the time period and whether you want to search
 * for instances across Switzerland or only at your own school. Then the next instances are
 * displayed and/or instances for which you have registered and/or all instances  you have searched for.
 * The user can press on each of the card to go into the detail view.
 *
 * @displayName Instance Search
 */
export default {
  name: "BInstanceSearch",
  props: {
    /**
     * the i18n key of the page title of the instance search page
     */
    tTitle: {type: String, required: false, default: "b.event.title"},
    /**
     * The label of the start date picker
     */
    tLabelStart: {type: String, required: false, default: "b.event.start"},
    /**
     * The label of the end date picker
     */
    tLabelEnd: {type: String, required: false, default: "b.event.end"},
    /**
     * The title of the next instance section
     */
    tTitleNextItem: {type: String, required: false, default: "b.event.next_event"},
    /**
     * The title of the further instance section
     */
    tTitleFurtherItem: {type: String, required: false, default: "b.event.title"},
    /**
     * The title of the archive instance section
     */
    tTitleArchiveItem: {type: String, required: false, default: "b.event.title"},
    /**
     * Indicates whether the instance search page should have the search input component
     */
    hasSearchInput: {type: Boolean, required: false, default: true},
    /**
     * Indicates whether the instance search page should have the search date picker components
     * - the from and end date
     */
    hasSearchDatePicker: {type: Boolean, required: false, default: true},
    /**
     * Indicates whether the instance search page should have the all clients switch component
     */
    hasAllClientsSwitch: {type: Boolean, required: false, default: false},
    /**
     * Indicates whether the instance search page should have the search button component
     */
    hasSearchButton: {type: Boolean, required: false, default: true},
    /**
     * Indicates whether the instance search page should have the instance section to show just one item
     */
    hasNextItem: {type: Boolean, required: false, default: true},
    /**
     * Indicates whether the instance search page should have the registered reference section
     */
    hasRegisteredItems: {type: Boolean, required: false, default: true},
    /**
     * Indicates whether the instance search page should have the instance section to show all items
     */
    hasFurtherItems: {type: Boolean, required: false, default: true},
    /**
     * Indicates whether the instance search page should have the instance section to show the archive
     */
    hasArchive: {type: Boolean, required: false, default: true},
    /**
     * The image to be displayed on the left of the card
     */
    image: { type: String, required: false, default: default_image },
    /**
     * Path to route to once a (instance) card is clicked
     */
    pathOnClick: { type: String, required: false, default: ""},
    /**
     * path to route to once a reference card is clicked
     */
    pathOnReferenceClick: { type: String, required: false, default: ""},
    /**
     * Specify which module is sought upon.
     * Depending on the client, it might not have the module you're looking for
     * @values marketplace, events, news, etc.
     */
    module: { type: String, required: false, default: "event"},
    /**
     * Specify the object type.
     * @values instances, references, etc.
     */
    objectType: { type: String, required: false, default: "instances"},
    /**
     * The label which is shown when the switch is on "true"
     */
    tLabelTrue: {type: String, required: false, default: "b.event.search_on_all_clients"},
    /**
     * The label which is shown when the switch is on "false"
     */
    tLabelFalse: {type: String, required: false, default: "b.event.search_in"},
  },
  created() {
    this.fetchItems();
    this.fetchArchiveItems();
    this.fetchNextItem();
  },
  data() {
    return {
      items: [],
      archiveItems: [],
      next_item: [],
      loading_items: false,
      loading_archiveItems: false,
      loading_item: false,
      total_number_of_items: undefined,
      total_number_of_archiveItems: undefined,
    };
  },
  methods: {
    search() {
      this.items = [];
      this.archiveItems = [];
      this.fetchItems();
      this.fetchArchiveItems();
      this.fetchNextItem();
    },
    fetchItems() {
      this.loading_items = true;
      let bom_query = this.$bom[this.module][this.objectType].all
        .skip(this.items.length)
        .take(6)
        .startingAfter(this.start_date)
        .endingBefore(this.end_date)
        .sortedBy("from")
        .ascending()
        .including("AddressRelationsPlugin");

      if (this.all_clients) {
        bom_query = bom_query.onAllClients();
      }
      if (this.user_search) {
        bom_query = bom_query.contains(this.user_search);
      }

      bom_query.fetch()
        .then(({
          instances,
          items_in_database,
        }) => {
          this.total_number_of_items = items_in_database;
          this.items = this.items.concat(instances);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading_items = false;
        });
    },
    fetchArchiveItems() {
      this.loading_archiveItems = true;
      let bom_query = this.$bom[this.module][this.objectType].all
        .skip(this.archiveItems.length)
        .take(6)
        .startingBefore(this.start_date)
        .sortedBy("from")
        .descending()
        .including("AddressRelationsPlugin");

      if (this.all_clients) {
        bom_query = bom_query.onAllClients();
      }
      if (this.user_search) {
        bom_query = bom_query.contains(this.user_search);
      }

      bom_query.fetch()
        .then(({
          instances,
          items_in_database,
        }) => {
          this.total_number_of_archiveItems = items_in_database;
          this.archiveItems = this.archiveItems.concat(instances);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading_archiveItems = false;
        });
    },
    fetchNextItem() {
      const today = set(new Date(), {
        minutes: 0,
        seconds: 0,
        miliseconds: 0,
      });
      this.loading_item = true;
      this.$bom[this.module][this.objectType].all
        .take(1)
        .startingAfter(today)
        .sortedBy("from")
        .including("AddressRelationsPlugin")
        .fetch()
        .then(({
          instances,
        }) => {
          this.next_item = instances;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading_item = false;
        });
    },
    onIntersect() {
      if (!this.loading_items && (this.items.length < this.total_number_of_items)) {
        this.fetchItems();
      }
    },
    onIntersectArchive() {
      if (!this.loading_archiveItems && (this.archiveItems.length < this.total_number_of_archiveItems)) {
        this.fetchItems();
      }
    },
  },
  computed: {
    user_search: {
      get() {
        return this.$route.query.search_term || "";
      },
      set(term) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            search_term: term,
          },
        });
      },
    },
    all_clients: {
      get() {
        return this.$route.query.show_all_events === "true";
      },
      set(choice) {
        if (choice) {
          this.$router.replace({
            query: {
              ...this.$route.query,
              show_all_events: "true",
            },
          });
        } else {
          this.$router.replace({
            query: {
              ...this.$route.query,
              show_all_events: "false",
            },
          });
        }
      },
    },
    start_date: {
      get() {
        const query_data = this.$route.query.start;
        if (query_data) {
          return new Date(query_data);
        }
        return set(new Date(), {
          minutes: 0,
          seconds: 0,
          miliseconds: 0,
        });
      },
      set(date) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            start: date.toISOString()
              .substring(0, 10),
          },
        });
      },
    },
    end_date: {
      get() {
        const query_data = this.$route.query.end;
        if (query_data) {
          return new Date(query_data);
        }
        return add(set(new Date(), {
          minutes: 0,
          seconds: 0,
          miliseconds: 0,
        }), {years: 1});
      },
      set(date) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            end: date.toISOString()
              .substring(0, 10),
          },
        });
      },
    },
  },
};
</script>

<style scoped>

</style>
