export default {
  hello: "Ein neues $bomify Projekt ist am Start :D",
  home: "Home",
  b: {
    impressum: {
      title: "Impressum",
      subtitle_text_pairs: [
        {
          subtitle: "Éditeur",
          texts: ["<strong>swissVR</strong><br>Campus Zug-Rotkreuz<br>6343 Rotkreuz<br>+41 41 757 67 11<br>info@swissvr.ch"],
        }, {
          subtitle: "Conseil et vente",
          texts: ["<strong>Bambus Software AG</strong><br>Sternmatt 6<br>6010 Kriens, LU<br>041 444 11 11<br>bambus@bambus.ch<br>www.bambus-software.ch"],
        },
      ],
    },
    who_is_who: {
      remembered: "Contacts mémorisés",
      contacts: "Mes Contacts",
    },
    search: "Rechercher",
    pages: {
      person_search: {
        title: "Carnet de contacts",
      },
      personal_address: {
        title: "Adresse",
        social_media: "Médias sociaux",
      },
    },
    sections: {
      correspondence: {
        correspondence: "Poste",
        newsletter: "Email",
        bill: "Facture",
      },
    },
  },
  login_failed: "Echec de la connexion",
  menu: {
    profile: {
      title: "Mon profil",
      address: "Adresse",
      communication: "Communication",
      setting: "Paramètres du compte",
    },
    who_is_who: {
      title: "Who's Who",
      search: "Recherche",
      remembered: "Mémorisés",
      contacts: "Coordonnées",
    },
    events: {
      title: "Offres",
      events: "Manifestations",
      education: "Formation continue",
    },
    publications: {
      manual: "Guides swissVR",
      monitor: "swissVR Monitor",
      news: "swissVR news",
      wiki: "Wiki CA",
      literature: "Littérature",
    },
    services: {
      title: "Services",
      statutes: "Statuts",
      insight: "Axon Insight Login",
      mediation: "Agence de recrutement pour CA",
      contact: "Contact du secrétariat",
    },
    marketplace: {
      title: "Place du marché",
      jobs: "Postes vacants",
      internships: "Stages",
      shop: "Annonces / Boutique",
    },
  },
  marketplace: {
    jobs: "Postes vacants",
    interships: "Stages",
    shop: "Annonces / Boutique",
  },
  documents: {
    report: "Rapports annuels",
    protocol: "GV Minutes",
    varia: "Varia",
  },
  sidemenu: {
    home: "Home",
    statutes: "Statuts",
    settings: "Paramètres",
    impressum: "Impressum",
  },
  events: {
    title: "Événements",
  },
  statutes: {
    file_url: "/images/events.jpg",
  },
  tabs: {
    profile: "Mon profil",
    communication: "Communication",
    impressum: "Impressum",
    address_details: "Détails de l'adresse",
    company_address: "Entreprise",
    event_search: "Recherche d'événements",
    noted_addresses: "Remarqué",
    contact_addresses: "Contact",
    event_details: "Détails de l'événement",
    person_search: "Who's Who",
    marketplace: "Place du marché",
    marketplace_details: "Entrée du marché",
    documents: "Documents",
    documents_details: "Entrée des documents",
    settings: "Paramètres",
    statutes: "Statuts",
    login: "Login",
  },
  insight: {
    title: "Axon Insight Login",
    additionalText: "Login: <strong>info@swissvr.ch</strong><br><br>Mot de passe: <strong>swissvr2016</strong><br>",
    button: "Manuel",
  },
  mediation: {
    title: "Agence de recrutement pour CA",
    button: "médiations",
  },
  sidemenu: {
    home: "Page d'acceuil",
    profile: "Mon profil",
    who_is_who: "Carnet de contacts",
    events: "Offres",
    statutes: "Services",
    settings: "Réglages",
    impressum: "Impressum",
  },
};
