<template>
  <div class="login-container">
    <div class="loading">
      <b-loading-circle
        :message="$t('wait')"
      />
    </div>
    <div class="login-iframe">
      <b-login
        @success="routeForward"
        @error="error = $event"
      />
    </div>
    <b-error-alert :value="!!error" :message="$t('login_failed')" />
  </div>
</template>

<script>
export default {
  name: "Login",
  data: () => ({
    error: undefined,
  }),
  created() {
    if (this.$bom.logged_in) {
      this.routeForward();
    }
  },
  methods: {
    routeForward() {
      this.$router.replace({name: "clientChooser", query: this.$route.query, params: this.$route.params});
    },
  },
};
</script>

<style scoped>
div.login-container {
  position: relative;
  width: 340px;
  height: 450px;
}
div.loading {
  position: absolute;
  top: 100px;
  left: 0;
  width: 100%;
}
.login-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: whitesmoke;
  border: none;
  border-radius: 6px;
  display: block;
}
</style>
